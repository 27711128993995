
<template>
        <div class="market-profile-inner" v-if="stock_capital">
           
            <ul class="m-pointer" style="margin-bottom: 0;">
                <li class="li-head">
                    <div class="profile-inner-table-row">
                        <div class="profile-inner-table-name">خصیصه</div>
                        <div class="profile-inner-table-value">مقدار</div>
                    </div>
                </li>
                <li v-for="(item, index) in stock_capital" :key="index" :class="activeLi == index ? 'active-current' : ''">
                    <div class="profile-inner-table-row" @click="setActive(index)">
                        <div class="profile-inner-table-name">تاریخ</div>
                        <div class="profile-inner-table-value">{{ item.date }}</div>
                        <div class="profile-inner-table-actions actions-min-w"><i class="uil uil-angle-down"></i></div>
                    </div>
                    <div class="profile-inner-table-box">
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">افزایش</div>
                                <div class="profile-inner-table-value">{{ item.value }}%</div>
                            </div>
                        </div>
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">مهلت پذیره نویسی</div>
                                <div class="profile-inner-table-value">{{ item.mohlat_pazirenevisi }}</div>
                            </div>
                        </div>
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">تاریخ ثبت</div>
                                <div class="profile-inner-table-value">{{ item.tarikh_sabt ? item.tarikh_sabt : '-' }}</div>
                            </div>
                        </div>
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">سرمایه قبلی</div>
                                <div class="profile-inner-table-value">{{ item.sarmaye_ghabli }}</div>
                            </div>
                        </div>
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">سرمایه جدید</div>
                                <div class="profile-inner-table-value">{{ item.sarmaye_jadid }}</div>
                            </div>
                        </div>
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">تغییر سرمایه</div>
                                <div class="profile-inner-table-value">{{ item.taghir_sarmaye }}</div>
                            </div>
                        </div>
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">آورده</div>
                                <div class="profile-inner-table-value">{{ avarde_change(item.avarde) }} ({{ avarde_change_percentage(item.avarde) }}%)</div>
                            </div>
                        </div>
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">اندوخته</div>
                                <div class="profile-inner-table-value">{{ '-' }}</div>
                            </div>
                        </div>
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">صرف سهام</div>
                                <div class="profile-inner-table-value">{{ '-' }}</div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>

    <!-- <div v-if="stock_capital">
        <div v-for="(item, index) in stock_capital" :key="index">
        تاریخ: {{ item.date }} <br>
        افزایش: {{ item.value }}%<br>
        مهلت پذیره نویسی: {{ item.mohlat_pazirenevisi }} <br>
        تاریخ ثبت: {{ item.tarikh_sabt ? item.tarikh_sabt : '-' }} <br>
        سرمایه قبلی: {{ item.sarmaye_ghabli }} <br>
        سرمایه جدید: {{ item.sarmaye_jadid }} <br>
        تغییر سرمایه: {{ item.taghir_sarmaye }} <br>
        آورده: {{ avarde_change(item.avarde) }} ({{ avarde_change_percentage(item.avarde) }}%)<br>
        اندوخته: {{ '-' }} <br>
        صرف سهام: {{ '-' }} <br>
        ---------------------------------------------------------------------<br>
        </div>
    </div> -->
</template>

<script>
export default {
    name: 'StockCapital',
    data() {
        return {
            stock_capital : null,
            widgetLoad : true,
            activeLi: null
        }
    },
    mounted() {
        this.getProfileCapital();
    },
    methods: {
        avarde_change_percentage(item) {
                return JSON.parse(item).change_percentage ?? '-';
        },
        avarde_change(item) {
                return JSON.parse(item).change ?? '-';
        },
        getProfileCapital() {
            this.$helpers
                .makeRequest("GET", `/stock/profile-capital/${this.$route.params.name}`)
                .then((api_response) => {
                    if (api_response.status == 200) {
                        this.stock_capital = api_response.data.response.capital.body.response.items;
                        this.widgetLoad = false;
                        // this.stock_header_summary = api_response.data.response.detail.response.summary;
                        // $('.stocks-profile').append('<link rel="stylesheet" type="text/css" href="https://static.tgju.org/components/tgju-fix/fix-custom.css">');
                        // $('body').addClass('internal profile fs-grid mprofile');
                    }
                });
        },
        // این متد جهت نمایش بهتر نرخ ها و قیمت ها می باشد // جدا کننده قیمت با کاما
        formatPrice(value) {
            if (!value) {
                // this.hide_demands = true;
                return '-';
            }
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        intToString(value) {
            if ((value).length > 12) {
                return 'هزار میلیارد';
            } else if ((value).length > 9) {
                return 'میلیارد';
            } else if ((value).length > 6) {
                return 'میلیون';
            } else if ((value).length > 3) {
                return '';
            } else {
                return '';
            }
        },
        roundInt(value) {
            if ((value).length > 12) {
                return parseFloat(parseFloat(value / Math.pow(1000,4)).toFixed(3));
            } else if ((value).length > 9) {
                return parseFloat(parseFloat(value / Math.pow(1000,3)).toFixed(3));
            } else if ((value).length > 6) {
                return parseFloat(parseFloat(value / Math.pow(1000,2)).toFixed(3));
            } else if ((value).length > 3) {
                return this.formatPrice(value);
            } else {
                return value;
            }
        },
        setActive(index){
            if(index == this.activeLi){
                this.activeLi = null;
            }else{
                this.activeLi = index;
            }
        },
    },
}
</script>